import { Dispatch, AnyAction } from 'redux'
import { get } from 'lodash'

import axios, { makeRequest } from '../../utils/axios'
import { Ranking, RankingColumn, RankingParameter } from './types'
import StoreTypes from '../../types/StoreType'

export const ADD_RANKINGS_PARAMETERS = 'ADD_RANKINGS_PARAMETERS'
export const REMOVE_RANKINGS_PARAMETER = 'REMOVE_RANKINGS_PARAMETER'
export const ADD_COLUMN_TO_RANKING = 'ADD_COLUMN_TO_RANKING'
export const REMOVE_COLUMN_FROM_RANKING = 'REMOVE_COLUMN_FROM_RANKING'
export const UPDATE_RANKING_COLUMNS_LIST = 'UPDATE_RANKING_COLUMNS_LIST'

export const resetDataAfterFail = (
  dispatch: Dispatch<AnyAction>,
  getState: () => StoreTypes
) => {
  const state = getState()
  const prevRankingColumnsList = get(state.ranking.data, 'rankingCols', [])

  const rollBackPrevListAfterReqFail = () => {
    dispatch({
      type: UPDATE_RANKING_COLUMNS_LIST,
      payload: {
        list: prevRankingColumnsList,
      },
    })
  }

  return rollBackPrevListAfterReqFail
}

export const addColumnToRanking = (
  rankingId: Ranking['id'],
  column: RankingColumn,
  columnType: RankingColumn['columnType']
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/rankings/${rankingId}/parameters`, [
        {
          parameterId: column.id,
          priority: column.priority,
          isSponsoredRanking: column.column.isSponsoredRanking,
        },
      ]),
    successText: 'Column added to ranking offers',
  })

  if (createResponse.data) {
    dispatch({
      type: ADD_COLUMN_TO_RANKING,
      payload: {
        column: {
          ...column,
          columnType,
        },
      },
    })

    dispatch({
      type: ADD_RANKINGS_PARAMETERS,
      payload: createResponse.data,
    })
  }
}

export const removeColumnFromRanking = (
  rankingId: Ranking['id'],
  columnId: RankingColumn['id'],
  columnType: RankingColumn['columnType'],
  isSponsoredRanking?: RankingParameter['isSponsoredRanking']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/rankings/${rankingId}/parameters/${columnId}`, {
        params: { columnId, isSponsoredRanking },
      }),
    successText: 'Column removed from offers table',
  })

  if (deleteResponse) {
    dispatch({
      type: REMOVE_COLUMN_FROM_RANKING,
      payload: {
        columnId,
        columnType,
        isSponsoredRanking,
      },
    })

    dispatch({
      type: REMOVE_RANKINGS_PARAMETER,
      payload: {
        columnId,
        columnType,
        isSponsoredRanking,
      },
    })
  }
}

export const updateRankingColumns = (
  rankingId: Ranking['id'],
  list: RankingColumn[]
) => async (dispatch: Dispatch<AnyAction>, getState: () => StoreTypes) => {
  const priorityList = list.map((col, index) => ({
    priority: index,
    parameterId: col.columnId,
  }))

  dispatch({
    type: UPDATE_RANKING_COLUMNS_LIST,
    payload: {
      list,
    },
  })

  await makeRequest({
    axiosRequest: () =>
      axios.patch(`/rankings/${rankingId}/parameters`, priorityList),
    successText: 'Columns order updated',
    errorCallback: resetDataAfterFail(dispatch, getState),
  })
}
